import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {getOrgStructures} from "../../../../../../selectors/CatalogSelectors";
import {Select} from "../../../../../Inputs/SelectField";
import {
  fetchCounterpartyClasses,
  fetchOwnerTypes,
  selectCounterpartyClasses,
  selectOwnerTypes,
} from "../../../FinSettingsSlice";

const CounterPartyLine = ({namePart}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const orgStructures = useSelector(getOrgStructures);
  const ownerTypes = useSelector(selectOwnerTypes);
  const counterpartyClasses = useSelector(selectCounterpartyClasses);
  useEffect(() => {
    if (ownerTypes?.length === 0) dispatch(fetchOwnerTypes());
    if (counterpartyClasses?.length === 0) dispatch(fetchCounterpartyClasses());
  }, []);

  const orgStructuresOptions = useMemo(
    () =>
      orgStructures.map(item => {
        return {
          title: item.shortName,
          value: item.orgStructureId,
        };
      }),
    [orgStructures],
  );
  const ownerTypeOptions = ownerTypes?.map(type => ({title: t(type.ownerType), value: type.ownerTypeId})) ?? [];

  const counterpartyClassOptions =
    counterpartyClasses?.map(clas => ({title: clas?.number?.toString(), value: clas?.classId})) || [];

  return (
    <div className="row gap w100">
      <Select name={`officeId${namePart}`} label={t("Offic")} options={orgStructuresOptions} autolabel />
      <Select
        name={`counterpartyTypeId${namePart}`}
        label={t("Counterparty Type")}
        options={ownerTypeOptions}
        autolabel
      />
      <Select
        name={`counterpartyClassId${namePart}`}
        label={t("Counterparty Class")}
        options={counterpartyClassOptions}
        autolabel
      />
    </div>
  );
};

export default CounterPartyLine;
