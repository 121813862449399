import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {MoneyInput, NumberInput, TextAreaInput, TextInput} from "../../../../../Inputs";

import CounterPartyLine from "./CounterPartyLine";

const MiniOverDraftParams = ({
  onPauseMiniOverdraftBtnClick,
  onRestoreMiniOverdraftBtnClick,
  onDeleteMiniOverdraftBtnClick,
  onSaveMiniOverdraftBtnClick,
}) => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  const {creditType, currentParamId, statusId} = values;

  const overdraftSavebtnDisabled = !(
    values?.overdraftName &&
    values?.counterpartyClassIdMiniOverDraft &&
    values?.counterpartyTypeIdMiniOverDraft &&
    values?.officeIdMiniOverDraft
  );

  return (
    <div className={"fcCol gap aistr " + (!(creditType === 1 || !creditType) && "disabled-div")}>
      <div className="header asfs">{`1. ${t("Mini-overdraft params")}`}</div>
      <div className="italic">{t("miniOverdraftParamsPartText")}</div>
      <CounterPartyLine namePart="MiniOverDraft" />
      <TextInput label="Overdraft" name="overdraftName" autolabel width="15rem" required />
      <div className="row gap aibl">
        {t("debtAmount")}
        <div className="row gap aibl">
          <MoneyInput name="sumTo" label="to" autolabel />
          {t("and")}
          <NumberInput name="percentageTo" label={t("to %")} autolabel width="5rem" />
        </div>
      </div>
      <TextAreaInput name="miniDraftParamsNote" placeholder={t("notes")} />
      <div className="row">
        <div className="row gap">
          <FunctionButton
            name="Pause"
            autoWidth
            disabled={!(currentParamId && creditType === 1 && statusId === 1)}
            onClick={onPauseMiniOverdraftBtnClick}
          />
          <FunctionButton
            name="Restore"
            autoWidth
            disabled={!(currentParamId && creditType === 1 && statusId === 2)}
            onClick={onRestoreMiniOverdraftBtnClick}
          />
        </div>
        <div className="row gap">
          <FunctionButton
            name="Delete"
            autoWidth
            disabled={!(currentParamId && creditType === 1)}
            onClick={onDeleteMiniOverdraftBtnClick}
          />
          <FunctionButton
            name="Save"
            // disabled={creditType === 1 && statusId === 2}
            autoWidth
            disabled={overdraftSavebtnDisabled}
            onClick={onSaveMiniOverdraftBtnClick}
          />
        </div>
      </div>
    </div>
  );
};

export default MiniOverDraftParams;
