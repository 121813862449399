import {useEffect, useMemo, useState} from "react";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {fetchData, fetchList} from "../../../../../hook/axios.hook";
import {numberFormat} from "../../../../../utilities/moneyFormat";
import {getInfo} from "../../../../../utilities/toasts";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {dateFormat} from "./../../../../../utilities/dateFormat";
import {swalCustom} from "./../../../../../utilities/hooks/swalCustom";
import {CREDIT_OVERDRAFT_TABLE_COLUMNS} from "./constants";

const useCreditOverdraftTab = () => {
  const [creditParams, setCreditParams] = useState([]);

  const [currentParam, setCurentParam] = useState([]);

  const {values, setValues, setFieldValue, resetForm} = useFormikContext();

  useEffect(() => {
    updateList();
    if (values?.currentParamId) {
      onSelectParam(values?.currentParamId);
    }
  }, []);

  useEffect(() => {
    if (!currentParam) return;
    setValues(prev => {
      const isMiniOverdraft = currentParam?.creditType?.typeId === 1;
      const typePrefix = isMiniOverdraft ? "MiniOverDraft" : "Credit";
      return {
        ...prev,
        creditType: currentParam?.typeId,
        statusId: currentParam?.statusId,
        ["counterpartyClassId" + typePrefix]: currentParam?.counterpartyClassId,
        ["counterpartyTypeId" + typePrefix]: currentParam?.ownerTypeId,
        ["officeId" + typePrefix]: currentParam?.orgStructureId,
        [isMiniOverdraft ? "miniDraftParamsNote" : "repaymentComment"]: currentParam?.comment,
        annualInterestRate: currentParam?.annualInterestRate,
        repaymentTermsId: currentParam?.repaymentTermsId,
        repaymentTermsSumId: currentParam?.repaymentTermsSumId,
        creditName: currentParam?.creditType?.typeId === 2 && currentParam?.name,
        overdraftName: currentParam?.creditType?.typeId === 1 && currentParam?.name,
        ...(currentParam?.creditType?.typeId === 1
          ? {
              sumTo: currentParam?.sumTo,
              percentageTo: currentParam?.percentageTo,
              miniDraftParamsNote: currentParam?.comment,
            }
          : {}),
      };
    });
  }, [currentParam]);

  const updateList = () => {
    fetchList(`finance/creditParams/getAll`)
      .then(res => {
        setCreditParams(res);
      })
      .catch(() => {});
  };

  const createParam = async data => {
    return fetchData(`/finance/creditParams/create`, "post", data)
      .then(res => {
        updateList();
        onSelectParam(res?.paramId);
        return res;
      })
      .catch(() => {});
  };
  const updateParam = async (paramId, data) => {
    return fetchData(`/finance/creditParams/update/${paramId}`, "patch", data)
      .then(res => {
        getInfo(t("Parametr is updated"));
        updateList();
        onSelectParam(res?.paramId);

        return res;
      })
      .catch(() => {});
  };

  const deleteParam = async paramId => {
    return fetchData(`/finance/creditParams/remove/${paramId}`, "delete")
      .then(() => {
        // if (res) {
        getInfo(t("Parametr is deleted"));
        setCreditParams(prev => prev?.filter(param => param?.paramId !== paramId));
        setCurentParam(null);
        setFieldValue("currentParamId", null);
        resetForm();
        return true;
        // }
      })
      .catch(() => {});
  };
  //MiniOverDraftParams Part
  const onPauseMiniOverdraftBtnClick = () => {
    swalCustom.confirm({
      text: "Pause this param?",
      confirmFunc: () => {
        updateParam(currentParam?.paramId, {statusId: 2});
        setFieldValue("statusId", 2);
      },
    });
  };
  const onRestoreMiniOverdraftBtnClick = () => {
    swalCustom.confirm({
      text: "Restore this param?",
      confirmFunc: () => {
        updateParam(currentParam?.paramId, {statusId: 1});
        setFieldValue("statusId", 1);
      },
    });
  };
  const onDeleteMiniOverdraftBtnClick = () => {
    swalCustom.confirm({
      confirmFunc: () => {
        deleteParam(currentParam?.paramId).then(() => {
          // toaster.success("Mini overdraft deleted");
          // setValues(prev => ({
          //   ...prev,
          //   counterpartyClassIdMiniOverDraft: "",
          //   counterpartyTypeIdMiniOverDraft: "",
          //   officeIdMiniOverDraft: "",
          //   sumTo: "",
          //   percentageTo: "",
          //   comment: "",
          // }));
          // resetForm();
          // updateList();
        });
      },
    });
  };
  const onSaveMiniOverdraftBtnClick = () => {
    const newValues = {
      typeId: 1,
      counterpartyClassId: values?.["counterpartyClassId" + "MiniOverDraft"],
      ownerTypeId: values?.["counterpartyTypeId" + "MiniOverDraft"],
      orgStructureId: values?.["officeId" + "MiniOverDraft"],
      sumTo: values?.sumTo,
      percentageTo: values?.percentageTo,
      comment: values?.miniDraftParamsNote,
      name: values?.overdraftName,
    };
    if (currentParam?.creditType?.typeId === 1) {
      updateParam(currentParam?.paramId, newValues);
      return;
    }
    createParam(newValues);
  };

  //CreditParams Part
  const onPauseCreditBtnClick = () => {
    swalCustom.confirm({
      text: "Pause this param?",
      confirmFunc: () => {
        updateParam(currentParam?.paramId, {statusId: 2});
        setFieldValue("statusId", 2);
      },
    });
  };
  const onSaveCreditBtnClick = () => {
    const newValues = {
      typeId: 2,
      counterpartyClassId: values?.["counterpartyClassId" + "Credit"],
      ownerTypeId: values?.["counterpartyTypeId" + "Credit"],
      orgStructureId: values?.["officeId" + "Credit"],
      annualInterestRate: values?.annualInterestRate,
      repaymentTermsId: values?.repaymentTermsId,
      repaymentTermsSumId: values?.repaymentTermsSumId,
      comment: values?.repaymentComment,
      name: values?.creditName,
    };
    if (currentParam?.creditType?.typeId === 2) {
      updateParam(currentParam?.paramId, newValues);
      return;
    }
    createParam(newValues);
  };

  const onDeleteCreditBtnClick = () => {
    swalCustom.confirm({
      confirmFunc: () => {
        deleteParam(currentParam?.paramId).then(res => {
          if (res)
            setValues(prev => ({
              ...prev,
              counterpartyClassIdCredit: "",
              counterpartyTypeIdCredit: "",
              officeIdCredit: "",
            }));
        });
      },
    });
  };

  //table
  const columns = useColumns(CREDIT_OVERDRAFT_TABLE_COLUMNS);
  const defaultData = useMemo(
    () =>
      creditParams?.map((param, i) => ({
        number: ++i,
        id: param?.paramId,
        date: dateFormat(param?.createdAt),
        status: param?.status?.name,
        office: param?.orgStructure?.shortName,
        counterpartyType: param?.ownerType?.nameUkr,
        counterpartyClass: param?.counterpartyClass?.comment,
        fromToSum:
          param?.sumFrom || param?.sumTo
            ? numberFormat(param?.sumFrom) || "(*)" + " - " + numberFormat(param?.sumTo)
            : "",
        fromToSumPersentage:
          param?.percentageFrom || param?.percentageTo
            ? numberFormat(param?.percentageFrom) || "(*)" + " - " + numberFormat(param?.percentageTo)
            : "",
        notes: param?.comment,
        creditType: t(param?.creditType?.name),
        name: param?.name || "-",
      })),
    [creditParams],
  );

  const onSelectParam = paramId => {
    if (paramId === values?.currentParamId) return;
    if (!paramId) {
      resetForm();
      setCurentParam(null);
      return;
    }
    fetchData(`finance/creditParams/get/${paramId}`, "get")
      .then(res => {
        setFieldValue("currentParamId", res?.paramId);
        setCurentParam(res);
      })
      .catch(() => {});
  };

  return {
    columns,
    defaultData,
    onPauseMiniOverdraftBtnClick,
    onRestoreMiniOverdraftBtnClick,
    onDeleteMiniOverdraftBtnClick,
    onSaveMiniOverdraftBtnClick,
    onPauseCreditBtnClick,
    onSaveCreditBtnClick,
    onSelectParam,
    currentParam,
    onDeleteCreditBtnClick,
  };
};

export default useCreditOverdraftTab;
